<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
        <div class="app-modal__in">
            <div
                class="app-modal__header d-flex f-between p-5 m-title-modal"
            >
                <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
                    {{
                        $t("message.edit_m", {
                            m: $t("message.requirements_y"),
                        })
                    }}
                </p>
                <div>
                    <crm-store-update-close
                        :permission="$options.name"
                        :button_type="'store'"
                        :loading="loadingButton"
                        @c-submit="submit(true)"
                        @c-close="close()"
                    ></crm-store-update-close>
                </div>
            </div>
        </div>

        <div class="app-modal__body p-5 pb-0">
            <div class="timeline-items__right rounded-sm w-100 p-4 req-form" :class="mode ? 'table__myday' : 'table__mynight'">
              <el-form ref="form" status-icon :model="form" :rules="rules" >
                <el-row :gutter="20">
                  <el-col :span="24">
                    <el-form-item
                      :label="$t('message.name')"
                      prop="name"
                      class="label_mini"
                      :class="mode ? 'input__day' : 'input__night'"
                    >
                      <crm-input
                        :size="'medium'"
                        :inputValue="form.name"
                        v-model="form.name"
                        :placeholder="$t('message.name')"
                      ></crm-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <select-company
                    :size="'medium'"
                    :id="form.branch_id"
                    v-model="form.branch_id"
                  ></select-company>
                  </el-col>
                  <el-col :span="24">
                      <el-form-item
                        :label="$t('message.description')"
                        prop="description"
                        class="label_mini"
                        :class="mode ? 'input__day' : 'input__night'"
                      >
                    </el-form-item>
                      <vue-editor v-model="form.description" :editorToolbar="customToolbar" :class="mode ? 'input__day' : 'input__night'"></vue-editor>
                        <!-- <el-input
                          type="textarea"
                          :rows="2"
                          :placeholder="$t('message.description')"
                          :inputValue="form.description"
                          v-model="form.description"
                          class="text-area-el"
                        ></el-input> -->
                    </el-col>
                    <!-- <el-col :span="24">
                     
                    </el-col> -->
                  </el-row>
                </el-form>
            </div>
        </div>
        <!-- end app-modal__body -->
    </div>
</div>
</template>
<script>
import { i18n } from "@/utils/i18n";
import selectCompany from "@/components/filters/inventory/select-or-hidden-company";
import { mapGetters, mapActions } from "vuex";
import { VueEditor } from "vue2-editor";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";

export default {
  mixins: [form, drawer, show],
  name: "RequirementController",
  components: { selectCompany, VueEditor },
  data() {
    return {
      customToolbar: [

              [{ size: ["small", false, "large", "huge"] }],

              ["bold", "italic", "underline", "strike"],
                [
                  { align: "" },
                  { align: "center" },
                  { align: "right" },
                  { align: "justify" }
                ],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ color: [] }, { background: [] }],

                ["link"],

          
            ["clean"]
            ],
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "requirement/rules",
      model: "requirement/model",
      columns: "requirement/columns",
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      update: "requirement/update",
      show: "requirement/show",
    }),
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.update(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (close) this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.text-area-el{
  .el-textarea__inner{
    min-height: 500px !important;
  }
}
.req-form .ql-snow .ql-tooltip{
  left: 0 !important;
}
.input__night{
  .ql-picker{
    color: #fff !important;
  }
  .ql-stroke{
    stroke:#fff !important;
  }
  .ql-fill{
    stroke:#fff !important;

  }
  .ql-active{
    .ql-stroke{
      stroke:#06c !important;
    }
    .ql-fill{
      stroke:#06c !important;
    }
  }
  .ql-snow .ql-picker-options{
    background-color: #323248;
  }
}
.quillWrapper.input__night{
  background-color: #323248 ;
  .ql-toolbar.ql-snow{
    border-color: #565658 ;
  }
  .ql-container.ql-snow{
    border-color: #565658 ;

  }
}
</style>

